import { createApp } from 'vue'
import App from './App.vue'
import VueGtag from 'vue-gtag'

import './assets/bootstrap.min.css'
import 'bootstrap'
import router from './router.js'

const app = createApp(App)

app.config.productionTip = true

// Configuration Analytics
if (document.location.hostname !== 'localhost') {
  app.use(VueGtag, {
    config: { id: 'G-YR8W8KLM5B' }
  }, router)
}
app.use(router)
app.mount('#app')
