<template>
  <div id="galleryContainer">
    <div id="galleryDescription">
      <h1 v-html="title"></h1>
      <p v-html="description"></p>
    </div>
    <div id="imageGallery">
      <div
        v-for="({ fileName, caption, portrait }, index) in collection"
        v-bind:key="`${fileName}-${index}`"
        class="card mb-5"
        :class="portrait ? 'portrait' : null"
      >
        <image-presenter :fileName="fileName" :portrait="portrait"        />
        <div v-if="caption" class="card-body">
          <p
            class="card-text"
            v-html="caption"
          >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImagePresenter from './ImagePresenter.vue'

export default {
  name: 'Image-Gallery',
  props: {
    collection: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: false
    }
  },
  components: {
    ImagePresenter
  },
  mounted() {
    // iOS Safari is silly so we need to scroll to top automatically
    window.scrollTo(0,1)
  }
}
</script>

<style scoped>
.portrait {
  max-width: 52%
}
.card {
  margin: auto;
}
img {
  max-width: 100%;
}
#galleryContainer {
  position: absolute;
  right: 0;
  left: 0;
  margin: inherit;
  max-width: inherit;
}

#imageGallery {
  float: right;
  width: 75%;
  padding: 1em;
}

#galleryDescription {
  float: right;
  width: 25%;
  padding: 1em;
  /* word-break: break-all; */
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}

/* On smaller screens */
@media only screen and (max-width: 1023px) {
  .card {
    max-width: 100% !important;
  }
  #galleryContainer {
    height: 100%;
    width: 100%;
    padding: unset;
    position: relative;
  }
  #imageGallery {
    width: 100%;
    height: unset;
    overflow-y: unset;
  }
  #galleryDescription {
    position: unset;
    height: unset;
    width: 100%;
  }
}
</style>
