<template>
  <div>
    <lazy-image
      class="image-width"
      @load="onImgLoad()"
      :src="src"
    />
    <div
      ref="placeholder"
      v-bind:style="{ height: placeholderHeight }"
      class="custom-placeholder"
      v-if="!loaded"
    >
      <div class="position-relative">
        <div class="centered">
          <div
            class="spinner-border"
            role="status"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LazyImage from './LazyImage.vue'

export default {
  name: 'ImagePresenter',
  props: {
    fileName: {
      type: String,
      required: true
    },
    portrait: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    LazyImage
  },
  mounted() {
    const ratio = this.portrait ? 1.4967 : 0.6666666666667
    const { clientWidth } = this.$refs.placeholder
    this.placeholderHeight = `${clientWidth * ratio}px`
  },
  methods: {
    onImgLoad () {
      this.loaded = true
    }
  },
  data () {
    return {
      loaded: false,
      src: require(`../assets/${this.fileName}`),
      placeholderHeight: '0px'
    }
  }
}
</script>

<style scoped>
.custom-placeholder {
  width: 100%;
  height: 100px;
}
.position-relative {
  position: relative;
  height: 100%;
}
.centered {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
