<template>
  <div id="galleryNavContainer">
    <router-link
      class="link"
      v-for="({
        title,
        cardImage,
        collection,
        description
      }, key) in collections"
      :key="key"
      :to="`/collections/${key}`"
    >
      <div class="card">
        <h3
          class="card-header"
          v-html="title"
        >
        </h3>
        <!-- <img src="../assets/splash-img.jpg" class="image-width"> -->
        <image-presenter :fileName="cardImage" class="image-width"/>
        <div class="card-body">
          <p
            class="card-text"
            v-html="description"
          >
          </p>
        </div>
        <div class="card-footer text-muted">
          {{ collection.length }} Photographs
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import ImagePresenter from './ImagePresenter.vue'

export default {
  name: 'gallery-navigator',
  props: {
    collections: {
      type: Object,
      required: true
    }
  },
  components: {
    ImagePresenter
  },
}
</script>

<style scoped>
.link {
  margin: 1em;
  text-decoration: none;
  height: 100%;
}
.link:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
}
img {
  max-width: 100%;
}
#galleryNavContainer {
  padding: 1em;
  justify-content: space-evenly;
  display: flex;
  flex-wrap: wrap;
}
.card {
  max-width: 25em;
  min-height: 35em;
}
</style>
