<template>
<div>
  <div ref="modal" class="modal" v-show="toggle">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" v-if="title">
            {{ title }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <slot>
            <p>Modal body text goes here.</p>
          </slot>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" v-if="saveButton">
            {{ saveLabel }}
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
            @click="closeModal"
          >
            {{ closeLabel }}
          </button>
        </div>
      </div>
    </div>
    <div class="modal-backdrop show" @click="closeModal"></div>
  </div>
</div>
</template>

<script>
export default {
  name: 'Modal-Dialog',
  props: {
    title: String,
    show: { type: Boolean, required: true },
    saveButton: { type: Boolean, default: true },
    saveLabel: { type: String, default: 'Save changes' },
    closeLabel: { type: String, default: 'Close' },
  },
  data() {
    return {
      toggle: this.show
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
      this.toggle = !this.toggle
    }
  },
  watch: {
    show: function (val) {
      this.toggle = val
    }
  }
}
</script>

<style scoped>
.modal-backdrop {
  z-index: -1;
}
.modal {
  display: block;
  overflow: scroll;
}
/* .modal-body {
  height: 100%;
  overflow-y: scroll;
} */
</style>
