<template>
  <div id="app">
    <nav-bar ref="navBar"></nav-bar>
    <div id="siteContent" v-bind:style="{ height: siteContentHeight}">
      <!-- route outlet -->
      <!-- component matched by the route will render here -->
      <div class="max-content-width">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue'

export default {
  name: 'App',
  components: { NavBar },
  methods: {
    onNavResize(/* mutations */) {
      this.siteContentHeight = `calc(100% - ${this.$refs.navBar.$el.offsetHeight}px)`
    }
  },
  mounted() {
    // set initial offsetHeight for siteContent
    this.siteContentHeight = `calc(100% - ${this.$refs.navBar.$el.offsetHeight}px)`

    // observe navBar for changes
    this.navObserver.observe(
      this.$refs.navBar.$el,
      { attributes: true, childList: true, subtree: true }
    )

    // iOS Safari is silly so we need to scroll to top automatically
    window.scrollTo(0,1)
  },
  data() {
    return {
      siteContentHeight: null,
      navObserver: new MutationObserver(this.onNavResize)
    }
  },
  unmounted() {
    this.navObserver.disconnect()
  }
}
</script>

<style>
body {
  padding: 0;
  margin: 0;
}
#app {
  width: 100%;
  height: 100vh;
  -webkit-overflow-scrolling: touch;
}
#siteContent {
  width: 100%;
  position: relative;

  /* remove visibilty of scrollbar */
  /* Firefox */
  scrollbar-width: none;
  /* Internet Explorer 10+ */
  -ms-overflow-style: none;
}
#siteContent::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}

.max-content-width {
  max-width: 85em;
  margin: auto; /* centers content */
}
/* On smaller screens */
@media only screen and (max-width: 1023px) {
  #siteContent {
    overflow-y: unset;
  }
  #app {
    height: 100%;
    overflow: unset;
  }
}
</style>
