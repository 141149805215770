<template>
  <div class="image-container" ref="container">
    <img ref="container" style="width: 100%" class="lazy" :data-src="src" @load="onImgLoad" />
  </div>
</template>
  
<script>
export default {
  name: "LazyImage",
  props: {
    src: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      observer: null
    };
  },
  mounted() {
    this.observer = new IntersectionObserver(this.onIntersection, {
      // root,
      // rootMargin: '0px 0px 300px 0px',
      threshold: 1 // 1.0 represents 100% visibility
    });
    this.observer.observe(this.$refs.container);
  },
  beforeUnmount() {
    this.observer.unobserve(this.$refs.container);
  },
  methods: {
    onIntersection(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const lazyImage = entry.target.querySelector(".lazy")
          lazyImage.src = lazyImage.dataset.src
          this.observer.unobserve(entry.target)
        }
      })
    },
    onImgLoad (event) {
      this.$emit('load', event)
    }
  },
};
</script>

<style scoped>
</style>
