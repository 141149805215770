<template>
  <div>
    <p>Sorry, Not Found...<strong>(404)</strong></p>
    <button @click="$router.back()" type="button" class="btn btn-primary">Go back</button>
  </div>
</template>

<script>
export default {
  name: 'Error-404'
}
</script>

<style scoped>
div {
  text-align: center;
}
</style>
