<template>
  <div>
    <nav id="navBar" class="navbar navbar-expand-lg navbar-light bg-light">
      <router-link class="navbar-brand" to="/" >Alex Yonge</router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarColor03"
        aria-controls="navbarColor03"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div v-bind:class="{ collapse: menuCollapse }" class="navbar-collapse" id="navbarColor03">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <router-link class="nav-link" to="/" >
              {{ recentWork.title }}
            </router-link>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              data-bs-toggle="dropdown"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Collections
            </a>
            <div v-bind:class="{ show: showSiteMenu }" class="dropdown-menu">
              <router-link
                class="dropdown-item"
                to="/collections"
              >
                View All
              </router-link>

              <div class="dropdown-divider"></div>

              <router-link
                class="dropdown-item"
                v-for="({
                  title
                }, key) in collections"
                :key="key"
                :to="`/collections/${key}`"
                v-html="title"
              />
            </div>
          </li>
          <li class="nav-item" >
            <router-link class="nav-link" to="/about">About</router-link>
          </li>
          <li class="nav-item" >
            <a class="nav-link" @click="showContactForm = true">Contact</a>
          </li>
        </ul>
      </div>
    </nav>

    <modal
      ref="modal"
      title="Contact"
      saveLabel="Send"
      :saveButton="false"
      closeLabel="Close"
      :show="showContactForm"
      @closeModal="showContactForm = false"
    >
      <dl>
        <dt><label>Instagram</label></dt>
        <dd><a target="_blank" href="https://www.instagram.com/alexyonge/">www.instagram.com/alexyonge</a></dd>
        <dt><label>Email</label></dt>
        <dd><a href="mailto:info@alexyonge.com">info@alexyonge.com</a></dd>
        <dt><label>Location</label></dt>
        <dd><p class="text-dark">Calgary, Alberta, Canada</p></dd>
      </dl>
    </modal>
  </div>
</template>

<script>
import Modal from './Modal.vue'
const {
  recentWork,
  ...collections
} = require('../config/collections.json')

export default {
  name: 'nav-bar',
  components: {
    Modal
  },
  data() {
    return {
      recentWork,
      collections,
      showSiteMenu: false,
      menuCollapse: true,
      showContactForm: false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#navBar {
  padding-left: 1em;
}
@media only screen and (max-width: 1023px) {
  #navBar.navbar-light {
    position: relative !important;
  }
}
.instagram {
  height: 2em;
}
.dropdown-menu {
  margin: 0 !important;
}
</style>
