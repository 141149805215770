import { createRouter, createWebHistory } from 'vue-router'

// 1. Import route components/views.
import Error404 from './components/Error404.vue'
import Gallery from './components/Gallery.vue'
import GalleryNavigator from './components/GalleryNavigator.vue'
import About from './pages/About.vue'

// config files
const {
  recentWork,
  ...collections
} = require('./config/collections.json')

const collectionRoutes = Object
  .entries(collections)
  .map(([key, collection]) => ({
      path: `/collections/${key}`,
      component: Gallery,
      props: collection
    })
  )

// 2. Define some routes
// Each route should map to a component.
const routes = [
  {
    path: '/',
    alias: [ '/recentWork', '/recent-work' ],
    component: Gallery,
    props: recentWork
  },
  {
    path: '/collections',
    component: GalleryNavigator,
    props: {
      collections: collections
    }
  },
  ...collectionRoutes,
  {
    path: '/about',
    component: About
  },
  {
    path: '/:catchAll(.*)',
    component: Error404,
    name: 'NotFound'
  }
]

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here
export default createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
