<template>
  <div class="about-content">
    <!-- <img src="../assets/about-me.jpg"> -->
    <image-presenter class="img" fileName="about-me.jpg"/>
    <div class="text-wrapper">
      <h2>About</h2>
      <p>
        Alex Yonge is a wilderness photographer, Canadian Rockies aficionado and developer/contributor for the hiking website <a class="alert-link" target="_blank" href="https://www.hikethecanadianrockies.com/">Hike the Canadian Rockies.</a>
      </p>

      <p>
        Raised in Canmore, Alberta, a small town in Canada’s Rocky Mountains, this is where he developed a passion for the natural world and a concern for the environmental challenges we face.  This is a major motivating factor for his work and inspires him to explore the great outdoors.
      </p>

      <p>
        Alex Yonge graduated from the Vancouver Institute of Media Arts in 2013 and holds a diploma in photography.
      </p>

      <p>
        He is currently based in Calgary, Alberta.
      </p>
    </div>

  </div>
</template>

<script>
import ImagePresenter from '../components/ImagePresenter.vue'

export default {
  name: 'About-Me',
  components: {
    ImagePresenter
  },
}
</script>

<style scoped>
a {
  text-decoration: underline;
}
.about-content {
    /* max-width: 650px; */
    width: 100%;
    /* padding: 1em; */
}
.img {
  float: left;
  width: 50%;
  padding: 1em;
}
.text-wrapper {
  float: right;
  padding: 1em;
  max-width: 50%;
}

/* On smaller screens */
@media only screen and (max-width: 699px) {
  .about-content {
      width: 100%;
      /* padding: 1em; */
  }
  .img {
    float: unset;
    width: 100%;
  }
  .text-wrapper {
    float: unset;
    padding-left: 1em;
    max-width: 100%;
  }
}
</style>
